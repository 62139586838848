<template>
    <div
        id="app"
        class="h-100"
        style="position: relative;"
        :class="[skinClasses]"
    >
        <transition name="fade" mode="out-in">
            <!-- Include your component inside the transition -->
            <component :is="layout">
                <router-view />
            </component>
        </transition>
        <div v-if="urgentMessages && urgentMessages.length > 0">
            <urgent-message-modal-vue ref="urgentModal" :message="urgentMessages[0]" @close="checkUrgentMessages"/>
        </div>
    </div>
</template>

<script>

// This will be populated in `beforeCreate` hook
    import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
    import { provideToast } from 'vue-toastification/composition'
    import { watch } from '@vue/composition-api'
    import useAppConfig from '@core/app-config/useAppConfig'
    import * as Sentry from '@sentry/vue'


    import { useWindowSize, useCssVar } from '@vueuse/core'

    import store from '@/store'
    import UrgentMessageModalVue from './views/SystemMessages/UrgentMessageModal.vue'
    import {getLocaleObjects, Locales} from '@/libs/enums/Locales'
    import Vue from 'vue'

    const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
    const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
    const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

    const LayoutFullWithNav = () => import('@/layouts/fullWithNav/LayoutFullWithNav.vue')


    export default {
        data() {
            return {
                checkSetupWizardTimer: null,
                msgInterval: 0
            }
        },
        components: {

            // Layouts
            LayoutHorizontal,
            LayoutVertical,
            LayoutFull,
            UrgentMessageModalVue,
            LayoutFullWithNav

        },
        // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
        // Currently, router.currentRoute is not reactive and doesn't trigger any change
        methods: {
            async setLanguage() {
                const lang = localStorage.getItem('language')
                if (lang) {
                    await this.$store.dispatch('user/setLanguage', lang)
                } else {
                    await this.$store.dispatch('user/setLanguage', Locales.Slovenian)
                }
            },
            handleWindowResize() {
                this.$store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
                document.documentElement.style.setProperty('--vh', `${(window.innerHeight * 0.01)}px`)
            },
            async checkSetupWizard() {
                if (this.$store.getters['user/loggedIn']) {
                    await this.$store.dispatch('setup/getSetupProgress')
                }
            },
            setMessages() {
                this.$store.dispatch('messages/set_messages')
                this.$store.dispatch('messages/set_unread_urgent_messages')
            },
            checkUrgentMessages() {
                if (this.urgentMessages.length > 0) {
                    if (this.$refs.urgentModal) {
                        this.$refs.urgentModal.showModal()
                    }
                }
            },
            async updateUserData() {
                try {
                    let res = await this.$http.get(`/api/client/v1/users/${this.$store.getters['user/userData'].id}`)
                    if (res.data.client_id !== this.$store.getters['user/userData'].client_id) {

                        await this.$http.get(`/api/client/v1/change_client/${res.data.client_id}`)

                        const res1 = await Vue.prototype.$http.get('/api/client/v1/users/permissions')
                        const permissions = res1.data ?? []

                        const res2 = await Vue.prototype.$http.get('/api/client/v1/client/data')
                        const clientData = res2.data ?? {}

                        this.$store.dispatch('user/setUserPermissions', permissions)
                        this.$store.dispatch('client/setClientData', clientData)

                        res = await this.$http.get(`/api/client/v1/users/${this.$store.getters['user/userData'].id}`)
                    }
                    await this.$store.dispatch('user/setUserData', res.data)
                } catch (err) {
                    Sentry.captureException(err)
                }

            }

        },
        computed: {
            loggedIn() {
                return this.$store.getters['user/loggedIn']
            },
            localeObjects() {
                return getLocaleObjects()
            },
            layout() {
                if (this.$route.meta.layout === 'full-with-nav') return 'layout-full-with-nav'
                if (this.$route.meta.layout === 'full') return 'layout-full'
                return `layout-${this.contentLayoutType}`
            },
            contentLayoutType() {
                return this.$store.state.appConfig.layout.type
            },
            urgentMessages() {
                const array = this.$store.getters['messages/getUnreadUrgentMessages']
                return array.reverse()
            }
        },
        async mounted() {
            this.$store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
            const vh = (window.innerHeight * 0.01)
            document.documentElement.style.setProperty('--vh', `${vh}px`)
            if (this.loggedIn) {
                await this.updateUserData()
            } else {
                await this.setLanguage()
            }

            if (this.$store.getters['user/loggedIn']) {
                await this.$store.dispatch('setup/getSetupProgress')
            }
            this.checkSetupWizardTimer = setInterval(async() => {
                await this.checkSetupWizard()
            }, 300000)
            this.$store.state.setup.firstLoad = true

            this.setMessages()
            this.msgInterval = setInterval(this.setMessages, 10000)
        },
        async beforeCreate() {

            const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

            // eslint-disable-next-line no-plusplus
            for (let i = 0, len = colors.length; i < len; i++) {
                $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
            }

            // Set Theme Breakpoints
            const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

            // eslint-disable-next-line no-plusplus
            for (let i = 0, len = breakpoints.length; i < len; i++) {
                $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
            }

            // Set RTL
            const {isRTL} = $themeConfig.layout
            document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
        },
        beforeDestroy() {
            clearInterval(this.checkSetupWizardTimer)
            clearInterval(this.msgInterval)
        },
        setup() {
            const { skin, skinClasses } = useAppConfig()
            // If skin is dark when initialized => Add class to body
            if (skin.value === 'dark') document.body.classList.add('dark-layout')
            else document.body.classList.add('light-layout')

            // Provide toast for Composition API usage
            // This for those apps/components which uses composition API
            // Demos will still use Options API for ease
            provideToast({
                hideProgressBar: true,
                closeOnClick: false,
                closeButton: false,
                icon: false,
                timeout: 3000,
                transition: 'Vue-Toastification__fade'
            })

            // Set Window Width in store
            store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
            const { width: windowWidth } = useWindowSize()
            watch(windowWidth, val => {
                store.commit('app/UPDATE_WINDOW_WIDTH', val)
            })

            return {
                skinClasses
            }
        },
        async created() {
            window.addEventListener('resize', this.handleWindowResize)
        },
        destroyed() {
            window.removeEventListener('resize', this.handleWindowResize)
        }
    }
</script>

<style>
    .vs__selected-options input::placeholder {
      opacity: 60%;
    }

    .dark-layout .vc-popover-content.vc-container {
        background-color: #2a2a2a!important;
        border-radius: 8px;
        border: 1px solid #9a9a9a;
    }

    .dark-layout .timePickerNoDate .vc-time .vc-select select{
        background-color: #3e3e3e!important;
        border: 1px solid #3e3e3e;
        color: white!important;
        scrollbar-width: thin;
    }

    .dark-layout .timePickerNoDate .vc-time span{
        color: white!important;
    }

    .dark-layout .vc-popover-content, .dark-layout .vc-container, .dark-layout .vc-pane-layout,  .dark-layout .vc-pane, .dark-layout .vc-pane-container, .dark-layout .vc-header, .dark-layout .vc-weeks  {
        border-radius: 8px!important;
    }

    .dark-layout .vc-pane-container {
        border-radius: 8px!important;
        border: none;
    }

    .modal-content {
        max-height: 90vh;
    }

    .modal-body {
        overflow-y: auto;
    }

    .apexcharts-svg{
        background: transparent!important;
    }

    .white-text .apexcharts-tooltip {
        background-color: #29292a !important;
        color: #b4b7bd !important;
    }

    .white-text .apexcharts-xaxistooltip{
        background-color: #29292a !important;
        color: #b4b7bd !important;
    }

    polygon, polygon:-webkit-autofill {
        fill: none!important;
    }

    .white-text {
        .apexcharts-yaxis-label,
        .apexcharts-xaxis-label,
        .apexcharts-legend-text,
        .apexcharts-legend-series,
        .apexcharts-yaxis {
            color: #b4b7bd !important;
            fill: #b4b7bd !important;
        }

        .apexcharts-tooltip {
            background-color: #29292a !important;
            color: #b4b7bd !important;
        }

        text {
            color: #b4b7bd !important;
            fill: #b4b7bd !important;
        }

        .apexcharts-treemap-series text {
            color: whitesmoke !important;
            fill: whitesmoke !important;
        }

        .apexcharts-svg{
            background: transparent!important;
        }

        polygon, polygon:-webkit-autofill {
            fill: none!important;
        }
    }

    .black-text {
        .apexcharts-yaxis-label,
        .apexcharts-xaxis-label,
        .apexcharts-legend-text,
        .apexcharts-legend-series,
        .apexcharts-yaxis {
            color: #6e6b7b !important;
            fill: #6e6b7b !important;
        }

        .apexcharts-tooltip {
            background-color: whitesmoke!important;
            color: #6e6b7b !important;
        }

        .apexcharts-yaxis text, .apexcharts-datalabels-group text {
            color: #6e6b7b !important;
            fill: #6e6b7b !important;
        }

        .apexcharts-svg{
            background: transparent!important;
        }

        polygon, polygon:-webkit-autofill {
            fill: none!important;
        }
    }

    .dark-layout .vs__deselect, .dark-layout .vs__selected {
        color: #B4BFB5!important;
        fill: #B4BFB5;
    }

    .ag-floating-filter-body,  .ag-input-wrapper {
        max-width: 100%;
    }

    .ag-popup{
        z-index: 5;
    }

    .tooltip-custom .arrow, .expanded-tooltip .arrow {
        display: none;
    }

    .fade-enter-active, .fade-leave-active {
      transition: opacity 0.5s;
    }
    .fade-enter, .fade-leave-to {
      opacity: 0;
    }

    .tooltip .arrow{
      display: none !important;
    }

    .disabled-bg{
        background:#c44569!important;
        color: white!important;
    }

    .disabled-bg-click{
        background:#c44569!important;
        color: white!important;
        pointer-events: none;
    }

    .disabled-items .custom-control-label {
        margin-bottom: 0!important;
    }

    .red .custom-control-input:checked ~ .custom-control-label::before{
        background-color: #ea5455!important;
    }

    .dark-layout .b-overlay div {
        background: #323232 !important;
    }
</style>